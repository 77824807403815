<template>
  <i class="awardIcon material-icons">emoji_events</i>
</template>

<style lang="scss" scoped>

.awardIcon {
  font-size: 45px;
  color: #FFF6C8;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  animation: blinking infinite 1.5s alternate;
}
@keyframes blinking {
  from { color: #FFF6C8; }
  to { color: #EEE2A1; }
}
</style>
