<template>
  <div class="vote" :class="{ active: data.voted }" @click.stop.prevent="preVoted">
    <i class="vote-icon material-icons" v-if="data.voted">star</i>
    <i class="vote-icon material-icons" v-else>star_border</i>
    <span class="vote-value">{{ data.votes }}</span>
  </div>
</template>

<script>
import { onBeforeUnmount, reactive, inject } from 'vue'
export default {
  props: ['game', 'entered'],
  setup (props) {
    const axios = inject('axios')
    const data = reactive({
      votes: props.game.votes,
      voted: props.game.voted,
      timer: null,
      voteCompleted: props.game.voted
    })
    const vote = () => {
      data.voteCompleted = true
      const params = new URLSearchParams()
      params.append('game_id', props.game.game_id)
      axios.post('/api/vote.php', params)
    }
    const preVoted = value => {
      if (!props.entered) return alert('一度ゲームへ参加後にいいね！できます')
      if (data.voteCompleted) return
      clearTimeout(data.timer)
      data.voted = !data.voted
      if (data.voted) {
        data.timer = setTimeout(vote, 5000)
        data.votes++
      } else {
        data.votes--
      }
    }
    onBeforeUnmount(() => {
      clearTimeout(data.timer)
      if (!data.voteCompleted && data.voted) vote()
    })
    return {
      data,
      preVoted
    }
  }
}
</script>

<style lang="scss" scoped>
.vote {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.active {
    color: var(--orange);
  }
  &-icon {
    font-size: 20px;
  }
  &-value {
    margin-left: 2px;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
