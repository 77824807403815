<template>
  <div>
    <div v-if="data" class="logContainer">
      <h2>
        絵しりとり No.{{ data.game_id }}
        <template v-if="data.current">（進行中）</template>
        <template v-else>（<router-link :to="{ query: answer ? {} : { answer: 'true' } }">{{ answer ? '回答を隠す' : '回答を見る' }}</router-link>）</template>
        <div class="date">{{ dayjs(data.created).format('YYYY/MM/DD') }}</div>
      </h2>
      <div class="theme" v-if="data.theme">お題「{{ data.theme }}」</div>
      <vote-button class="voteButton" :game="data" />
      <award-icon class="awardIcon" v-if="data.award" />
      <a :href="answer ? data.images.answer : data.images.quiz" target="_blank" v-if="!data.current">
        <img :src="answer ? data.images.answer : data.images.quiz" :alt="`絵しりとり No.${data.game_id}`">
      </a>
      <h2>イラスト一覧</h2>
      <ul class="messageList">
        <li class="messageList-item" v-for="v in data.illusts" :key="v.illust_id">
          <div class="messageList-item-date">{{ dayjs(v.created).format('YYYY/MM/DD HH:mm') }}</div>
          <div class="messageList-item-content">
            <user-name :user="v" />: <a :href="v.path" target="_blank">{{ answer || data.current ? v.title : '？？？' }}</a>
          </div>
        </li>
      </ul>
      <h2>チャット履歴</h2>
      <ul class="messageList">
        <li class="messageList-item" v-for="v in data.messages" :key="v.chat_id">
          <div class="messageList-item-date">{{ dayjs(v.created).format('YYYY/MM/DD HH:mm') }}</div>
          <div class="messageList-item-content" v-if="v.type === 'message'"><user-name :user="v" />: <p>{{ v.text }}</p></div>
          <div class="messageList-item-content" v-else-if="v.type === 'start'"><user-name :user="v" />さんがゲームを開始しました</div>
          <div class="messageList-item-content" v-else-if="v.type === 'rotate'"><user-name :user="v" />さんがターンを移動しました</div>
          <div class="messageList-item-content" v-else-if="v.type === 'enter'"><user-name :user="v" />さんが入室しました</div>
          <div class="messageList-item-content" v-else-if="v.type === 'leave'"><user-name :user="v" />さんが退室しました</div>
          <div class="messageList-item-content" v-else-if="v.type === 'reset'"><user-name :user="v" />さんがゲームをリセットしました</div>
        </li>
      </ul>
      <div class="back">
        <router-link :to="{ name: 'Log', params: { page: 1 }, query }">{{ awardMode ? '殿堂入り' : 'ログ' }}一覧に戻る</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import router from '@/router/index'
import VoteButton from '@/components/VoteButton'
import AwardIcon from '@/components/AwardIcon'
import UserName from '@/components/UserName'
import { computed, ref, inject } from 'vue'
export default {
  components: { VoteButton, AwardIcon, UserName },
  setup () {
    const axios = inject('axios')
    const gameId = computed(() => Number(router.currentRoute.value.params.gameId))
    const answer = computed(() => router.currentRoute.value.query.answer)
    const data = ref(null)
    const fetch = () => {
      axios.get(`/api/log_detail.php?id=${gameId.value}`).then(res => {
        data.value = res.data
      })
    }
    fetch()
    const awardMode = computed(() => Boolean(router.currentRoute.value.query.award))
    const query = computed(() => awardMode.value ? { award: 'true' } : {})
    return {
      dayjs,
      data,
      answer,
      query,
      awardMode
    }
  }
}
</script>

<style lang="scss" scoped>
.logContainer {
  position: relative;
  margin: auto;
  max-width: 1200px;
  padding: 10px;
  background: var(--bg-color);
  border-radius: var(--radius);
}
.theme {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: bold;
}
.voteButton {
  position: absolute;
  right: 10px;
  top: 40px;
}
.awardIcon {
  position: absolute;
  top: 26px;
  left: -10px;
}
.date {
  float: right;
  font-size: 12px;
}
.messageList {
  &-item {
    margin: 4px 0;
    font-size: 12px;
    &-date {
      margin-right: 1.2em;
      display: inline-block;
      vertical-align: middle;
    }
    &-content {
      display: inline-block;
      vertical-align: middle;
      color: var(--green);
      font-weight: normal;
      p {
        display: inline-block;
        color: var(--tx-color);
        vertical-align: middle;
      }
    }
    a, .userNameWrapper {
      vertical-align: middle;
      font-weight: bold;
    }
  }
}
.back {
  margin-top: 30px;
  text-align: center;
}
</style>
